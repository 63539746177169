<template>
  <div>
    <error-alert
      :fields="fields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="financialInfoCreateVal"
        v-slot="{}"
      >
        <b-row>
          <b-col
            v-for="field in ['card_number', 'cardholder_id', 'billing_address_id']"
            :key="field"
            md="6"
          >
            <component
              :is="fields[field].type"
              :ref="`${field}_ref`"
              v-model="financialInfoForm[field]"
              :field="fields[field]"
              :name="field"
              :is-editable="isEdit"
            />
          </b-col>
          <b-col md="6">
            <template>
              <ValidationProvider
                v-slot="{ errors }"
                ref="validationOfExpDate"
                name="exp_date"
                rules="validateExpirationDate"
              >
                <component
                  :is="fields[field].type"
                  v-for="field in ['exp_date']"
                  :key="field"
                  :ref="`${field}_ref`"
                  v-model="financialInfoForm[field]"
                  :field="fields[field]"
                  class="mb-0"
                  :name="field"
                  :is-editable="isEdit"
                />
                <span
                  v-if="errors.length"
                  class="text-danger"
                >{{ errors[0] }}</span>
              </ValidationProvider>
            </template>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <component
              :is="fields[field].type"
              v-for="field in ['card_type']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="financialInfoForm[field]"
              :field="fields[field]"
              :name="field"
              :is-editable="isEdit"
            />
          </b-col>
          <b-col md="6">
            <label class="inputLabel"> {{ $t('Status') }}</label>
            <component
              :is="fields[field].type"
              v-for="field in ['is_active']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="financialInfoForm[field]"
              :field="fields[field]"
              :name="field"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="$router.push({name: 'home-customers-financial-info-list'})"
              >
                {{ $t('Back to List') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="isCreateForm ? false : !isFormChanged"
                @click="submit()"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>
import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import {
  BButton, BCol, BForm, BRow, BFormGroup,
} from 'bootstrap-vue'
import { scrollToError } from '@core/utils/utils'
import { mapState } from 'vuex'
import CustomerName from '../../../components/CustomerName.vue'
import config from '../config'

export default {
  name: 'AddressForm',
  components: {
    ErrorAlert,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    CustomerName,
  },
  props: {
    isCreateForm: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      required,
      isSubmit: false,
    }
  },
  computed: {
    ...mapState('financialInfo', ['financialInfoForm', 'financialInfoFormClone']),
    ...mapState('customers', ['customer']),
    isFormChanged() {
      return JSON.stringify(this.financialInfoForm) !== this.financialInfoFormClone
    },
  },
  created() {
    extend('validateExpirationDate', {
      validate: value => {
        const [month, year] = value.split('/').map(Number)
        const currentYear = new Date().getFullYear() % 100
        const currentMonth = new Date().getMonth() + 1
        return year > currentYear || (year === currentYear && month >= currentMonth)
      },
      message: 'The Card is expired.',
    })
  },
  mounted() {
    if (this.isCreateForm) this.$store.commit(`${this.MODULE_NAME}/SET_FORM_CLONE`, this.financialInfoForm)
  },
  methods: {
    redirectToList(itemId) {
      this.$router.push({ name: 'home-customers-financial-info-list', params: { id: itemId } })
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.isSubmit)
    },
    submit() {
      this.error = {}
      this.$refs.financialInfoCreateVal.validate()
        .then(success => {
          this.isSubmit = true
          if (success && !this.$refs.validationOfExpDate.errors.length) {
            const data = this.financialInfoForm
            const finInfo = this.mappingFields(Object.keys(this.fields), data, {
              customer_id: this.customer.id,
              cardholder_id: data.cardholder_id?.id,
              billing_address_id: data.billing_address_id?.id,
              id: data.id,
            })
            this.sendNotification(this, finInfo, `${this.MODULE_NAME}/${this.financialInfoForm.id ? 'update' : 'create'}`)
              .then(() => {
                this.clear()
                this.redirectToList(finInfo.customer_id)
              })
              .catch(err => {
                this.error = err.response?.data
              })
              .finally(() => {
                this.isSubmit = false
              })
          } else {
            this.isSubmit = false
            scrollToError(this, this.$refs.financialInfoCreateVal)
          }
        })
    },
    clear() {
      this.$refs.financialInfoCreateVal.reset()
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM_CLONE`, {})
    },
    cancel() {
      this.clear()
      this.$router.push({ name: 'home-customers-financial-info' })
    },
    loader() {
      this.$refs.financialInfoCreateVal.reset()
      const smth = JSON.parse(this.financialInfoFormClone)
      console.log('smth', smth)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
  },
  setup() {
    const MODULE_NAME = 'financialInfo'
    const { fields } = config()
    return {
      MODULE_NAME,
      fields,
    }
  },
}
</script>
