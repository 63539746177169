<template>
  <div>
    <portal to="body-top">
      <customer-name title="" />
    </portal>
    <p class="font-medium-5 text-center font-weight-bolder">
      {{ $t('Add a Credit Card') }}
    </p>
    <financial-info-form
      ref="financial-info-form"
      :is-create-form="true"
    />
  </div>
</template>
<script>
import FinancialInfoForm from '../components/Form.vue'
import CustomerName from '../../../components/CustomerName.vue'

export default {
  name: 'CustomerCreate',
  components: {
    FinancialInfoForm,
    CustomerName,
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['financial-info-form']) {
      this.$refs['financial-info-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  mounted() {
      const def   = {
        card_number: '',
        exp_date: '',
      }
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, def)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM_CLONE`, def)
  },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
  },
  setup() {
    const MODULE_NAME = 'financialInfo'
    return {
      MODULE_NAME,
    }
  },
}
</script>

<style scoped>

</style>
